.contact-form-result, .order-form-result, .subscribe-form-result {
    margin-top: 1rem;
    text-align: center;
    color: #666;
}
p.form-message.form-success {
    color: #666 !important;
}
p.form-message.form-error {
    color: #ff4949 !important;
}
.contact-form-result.hidden, .order-form-result.hidden, .subscribe-form-result.hidden {
    display: none;
}
input#anothercontact { display: none; }
input#anotherwaycontact { display: none; }
input#anotheremailcontact { display: none; }
input#secondcontact { display: none; }

.button.primary-btn[disabled=disabled], .button.primary-btn:disabled {
    outline: none;
    border-color: #a9a9a9;
    background-color: #c3c3c3;
    color: #fff;
    -webkit-transition: all .5s;
    transition: all .5s;
    cursor: not-allowed;
    pointer-events: none;
}
.control.required.has-error textarea {
    box-shadow: none !important;
    border-color: #f92b60 !important;
}
p.form-message.form-success {
    color: #445060;
}
h4.order-intro {
    margin-right: 15rem;
    margin-left: 15rem;
    margin-bottom: 3rem;
}
.navbar-wrapper .custom-burger {
    justify-content: right!important;
    -webkit-box-align: end!important;
    -ms-flex-align: end!important;
    margin-right: 1rem!important;
    width: 14.6rem!important;
}
.language-button {
    margin-right: 1rem!important;
}

@media (max-width: 768px) {
.button.button-cta {
    padding: 22px 10px !important;
}
}

.audio-video-background {
    background-image: url("../../img/50-reasons-audio-book.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
}

.section.section-feature-grey {
    background-color: #fbfbfb;
}

.side-block .background-wrapper .video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
}
.css-carousel {
    padding-bottom: 4rem !important;
    }
.sold-out {
        color: red;
        font-size: 2em;
        line-height: 2em;
}