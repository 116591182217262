/* khmer */
@font-face {
    font-family: 'Siemreap';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/siemreap/v24/Gg82N5oFbgLvHAfNl1YXlg4hLmpf.woff2) format('woff2');
    unicode-range: U+1780-17FF, U+200C, U+25CC, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

@font-face {
	font-family: 'Content';
	font-style: normal;
	font-weight: 400, 600;
	src: local('LAFSFS'), url('../fonts/content-web-v3.7.woff') format('woff');
	unicode-range: U+1780-17FF, U+200C, U+25CC, U+21, U+24, U+28, U+29, U+2C, U+2D, U+2E, U+3A, U+3B, U+3F, U+AB, U+BB;
}